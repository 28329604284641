import AppFooter from '@/components/common/app-footer';
import AppHeader from '@/components/common/app-header';
import { PropsWithChildren } from 'react';
import './app.layout.scss';
import classNames from 'classnames';

export interface AppLayoutHeaderProps {}
export function AppLayoutHeader({ children }: PropsWithChildren<AppLayoutHeaderProps>) {
    return <section className="app_layout__header">{children}</section>;
}

export interface AppLayoutContentProps {}
export function AppLayoutContent({ children }: PropsWithChildren<AppLayoutContentProps>) {
    return <section className="app_layout__content">{children}</section>;
}

export interface AppLayoutFooterProps {}
export function AppLayoutFooter({ children }: PropsWithChildren<AppLayoutFooterProps>) {
    return <section className="app_layout__footer">{children}</section>;
}

export interface AppLayoutProps {
    isHome?: boolean;
}
export default function AppLayout({ children, isHome }: PropsWithChildren<AppLayoutProps>) {
    return (
        <section className={classNames(`app_layout`, isHome && 'app_layout--home')}>
            {!isHome && (
                <AppLayoutHeader>
                    <AppHeader />
                </AppLayoutHeader>
            )}
            <AppLayoutContent>{children}</AppLayoutContent>
            <AppLayoutFooter>
                <AppFooter />
            </AppLayoutFooter>
        </section>
    );
}
