import { Role } from '@/redux/user/slice';

export interface GrantedRoutes {
    guestRoutes: string[];
    adminRoutes: string[];
    memberRoutes: string[];
    enterpriseRoutes: string[];
    protectedRoutes: string[];
    enterpriseSupervisorRoutes: string[];
}
export type AccessRoutes = {
    [key in Role]: string;
};

export type AllowedRoles = Role.ENTERPRISE | Role.ENTERPRISE_MANAGER | Role.ENTERPRISE_SUPERVISOR | Role.VIEWING_USER;

export type AllowedRoutesForRole = {
    [key in AllowedRoles]: string[];
};

export enum routePrefixes {
    MERCHANDISE = '/merchandise',
    MEMBER = '/member',
    ENTERPRISE = '/enterprise',
}

const publicRoutes = {
    home: '/',
    contact: '/contact',
    notFound: '/404',
    merchandiseDetails: `${routePrefixes.MERCHANDISE}/[id]`,
};

export const guestRoutes = {
    login: '/login',
    forgetPassword: '/forget-password',
    resetPassword: '/reset-password',
    memberRegistration: routePrefixes.MEMBER + '/registration',
    enterpriseRegistrationSuccess: routePrefixes.ENTERPRISE + '/registration-success',
};

const enterpriseRoutes = {
    merchandiseRegistration: routePrefixes.MERCHANDISE + '/registration',
    merchandiseEdit: `${routePrefixes.MERCHANDISE}/edit/[id]`,
    enterpriseInquiryList: routePrefixes.ENTERPRISE + '/inquiries',
    merchandiseList: routePrefixes.ENTERPRISE + '/merchandise-list',
    enterpriseInfo: routePrefixes.ENTERPRISE + '/info',
    enterpriseEdit: routePrefixes.ENTERPRISE + '/edit',
};

const memberRoutes = {
    viewerMemberInfo: routePrefixes.MEMBER + '/info',
    memberFavorites: routePrefixes.MEMBER + '/favourites',
    memberEdit: routePrefixes.MEMBER + '/edit',
    memberInquiryList: routePrefixes.MEMBER + '/inquiries',
};

export const protectedRoutes = {
    ...enterpriseRoutes,
    ...memberRoutes,
};

export const appRoutes = {
    ...publicRoutes,
    ...guestRoutes,
    ...protectedRoutes,
};

const grantedRoutes: GrantedRoutes = {
    guestRoutes: Object.values(guestRoutes),
    adminRoutes: [],
    memberRoutes: Object.values(memberRoutes),
    enterpriseRoutes: Object.values(enterpriseRoutes),
    protectedRoutes: Object.values(protectedRoutes),
    enterpriseSupervisorRoutes: Object.values(enterpriseRoutes).filter(
        (route) => !route.includes(enterpriseRoutes.enterpriseInquiryList)
    ),
};

export const accessRoutes: AccessRoutes = {
    [Role.ADMIN]: appRoutes.home,
    [Role.GUEST]: appRoutes.home,
    [Role.VIEWING_USER]: appRoutes.home,
    [Role.ENTERPRISE]: appRoutes.enterpriseInquiryList,
    [Role.ENTERPRISE_MANAGER]: appRoutes.enterpriseInquiryList,
    [Role.ENTERPRISE_SUPERVISOR]: appRoutes.merchandiseList,
};

export const allowedRoutesForRole: AllowedRoutesForRole = {
    [Role.ENTERPRISE]: grantedRoutes.enterpriseRoutes,
    [Role.ENTERPRISE_MANAGER]: grantedRoutes.enterpriseRoutes,
    [Role.ENTERPRISE_SUPERVISOR]: grantedRoutes.enterpriseSupervisorRoutes,
    [Role.VIEWING_USER]: grantedRoutes.memberRoutes,
};

export default grantedRoutes;
