import Link from 'next/link';
import { pageRoutes } from '@/utils/routes/urls';
import './app-footer.scss';

interface FooterLink {
    url: string;
    name: string;
    newTab?: boolean;
    show: boolean;
}

const footerNavLinks: Array<FooterLink> = [
    { url: 'https://newstech.co.jp/company/', name: '運営会社', newTab: true, show: true },
    { url: pageRoutes.toS, name: '利用規約', show: true },
    { url: 'https://newstech.co.jp/cookie/', name: 'クッキーポリシー', newTab: true, show: true },
    { url: 'https://newstech.co.jp/privacy/', name: 'プライバシーポリシー', newTab: true, show: true },
    { url: pageRoutes.contact, name: 'お問い合わせ', show: true },
];

export default function AppFooter() {
    return (
        <div className="app-footer-container">
            <div className="app-footer-container__nav">
                {footerNavLinks
                    .filter((link) => link.show)
                    .map((link, idx) => (
                        <Link
                            href={link.url}
                            className="app-footer-container__nav__item"
                            key={`footerNavLink-${idx}`}
                            target={link.newTab ? '_blank' : '_self'}
                            rel={link.newTab ? 'noopener noreferrer' : ''}
                        >
                            {link.name}
                        </Link>
                    ))}
            </div>
            <div>Copyright © News Technology Inc. All Rights Reserved . </div>
        </div>
    );
}
