import Image from 'next/image';
import Link from 'next/link';
import { appRoutes } from '@/utils/routes/routeConfiguration';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { Roles, User } from '@/redux/user/slice';
import Menu from '../menu';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import './app-header.scss';

export interface AppHeaderProps {
    homepage?: boolean;
}

export default function AppHeader({ homepage }: AppHeaderProps) {
    const loggedInUser = useSelector<RootState, User>((state) => state.auth.loggedInUser);
    const router = useRouter();

    const [isShow, setIsShow] = useState(false);

    const handleClick = () => {
        setIsShow((prev) => !prev);
    };

    useEffect(() => {
        const closeMenu = (e: any) => {
            if (
                e.target &&
                !e.target.className.includes('app_header_container__content_section__right__login-btn--logged-in')
            ) {
                setIsShow(false);
            }
        };
        document.body.addEventListener('click', closeMenu);
        return () => document.body.removeEventListener('click', closeMenu);
    }, []);

    return (
        <header className={classNames('app_header_container', homepage && 'app_header_container--homepage')}>
            <section className="app_header_container__content_section">
                <Link role="button" href={appRoutes.home}>
                    <Image
                        alt="logo"
                        className="app_header_container__content_section__logo"
                        src="/assets/images/header/MTVlogo.svg"
                        width={213.5}
                        height={41.4}
                        loading="eager"
                        priority={true}
                    />
                </Link>
                {![appRoutes.login, appRoutes.contact, appRoutes.memberRegistration].includes(router.pathname) && (
                    <div className="app_header_container__content_section__right">
                        {loggedInUser.id && loggedInUser.id > 0 && (
                            <>
                                <Menu isActive={isShow} handleClick={handleClick}>
                                    <div
                                        className={`app_header_container__content_section__right__login-btn app_header_container__content_section__right__login-btn--logged-in`}
                                    ></div>
                                </Menu>
                            </>
                        )}
                        {loggedInUser.id && loggedInUser.id <= 0 && (
                            <>
                                <Link
                                    role="button"
                                    href={appRoutes.memberRegistration}
                                    className="app_header_container__content_section__right__register-btn"
                                    hidden={true}
                                >
                                    会員登録
                                </Link>
                                <Link
                                    href={appRoutes.login}
                                    className={`app_header_container__content_section__right__login-btn`}
                                >
                                    ログイン
                                </Link>
                            </>
                        )}
                    </div>
                )}
            </section>
        </header>
    );
}
