import { apiSlice } from '@/redux/api/apiSlice';
import {
    AuthState,
    ResetPassword,
    initialUser,
    setLoggedInToken,
    setLoggedInUser,
    setLoggedInUserContact,
} from './authSlice';
import { initialContact } from '../enterprise/slice';
import { API_ROUTES } from '@/utils/routes/apiRoutes';

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<AuthState, any>({
            query: (data) => ({
                url: API_ROUTES.login,
                method: 'POST',
                body: data,
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const res = await queryFulfilled;
                    dispatch(setLoggedInToken(res.data.loggedInToken));
                    dispatch(setLoggedInUser(res.data.loggedInUser));
                    dispatch(setLoggedInUserContact(res.data.contact));
                } catch (err) {
                    dispatch(setLoggedInToken(''));
                    dispatch(setLoggedInUser(initialUser()));
                    dispatch(setLoggedInUserContact(initialContact()));
                }
            },
            transformResponse: (response) => {
                try {
                    return {
                        loggedInToken: response.result.token,
                        loggedInUser: response.result.user,
                        contact: response.result.contact,
                    };
                } catch (err: any) {
                    return { loggedInToken: '', loggedInUser: initialUser(), contact: initialContact() };
                }
            },
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: API_ROUTES.logout,
                method: 'POST',
            }),
        }),
        forgetPassword: builder.mutation<any, { email: String }>({
            query: (data) => {
                return {
                    url: API_ROUTES.forgotPassword,
                    method: 'POST',
                    body: data,
                };
            },
        }),
        resetPassword: builder.mutation<any, ResetPassword>({
            query: (data) => {
                return {
                    url: API_ROUTES.resetPassword,
                    method: 'POST',
                    body: data,
                };
            },
        }),
    }),
});

export const { useLogoutMutation, useLoginMutation, useForgetPasswordMutation, useResetPasswordMutation } =
    authApiSlice;
