import { appRoutes, routePrefixes } from '@/utils/routes/routeConfiguration';
import HTTP_STATUS from '../httpStatus';

export const pageRoutes = {
    ...appRoutes,
    toS: '/terms-of-service',
    merchandiseDetails: '/merchandise/[id]',
    users: '/user',
    notFound: `/${HTTP_STATUS.NOT_FOUND}`,
    internalServerError: `/${HTTP_STATUS.INTERNAL_SERVER_ERROR}`,
    contact: '/contact',
    venture: routePrefixes.MERCHANDISE,
    tagSettings: '/tag-settings',
    genreSettings: '/genre-settings',
    chat: '/chat',
    ventureEdit: `${routePrefixes.MERCHANDISE}/edit`,
    ventureMemberInfo: `${routePrefixes.MERCHANDISE}/member-information`,
    userEdit: '/user/edit',
    userMemberInfo: '/user/member-information',
};

export const checkSettingsFlag = (value: 'OFF' | 'ON') => process.env.NEXT_PUBLIC_LIMIT_REGISTRATION_FLAG === value;
