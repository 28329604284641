import Image from 'next/image';
import { ReactNode } from 'react';

type ToastTypes = 'info' | 'danger' | 'success';

export interface ToastBodyProps {
    message: ReactNode;
    type?: ToastTypes;
}

export default function ToastBody({ message, type }: ToastBodyProps) {
    return (
        <div className="toast-body">
            <Image
                className="toast-body__image"
                src={
                    type === 'danger'
                        ? '/assets/icons/Alert-white.png'
                        : type === 'success'
                        ? '/assets/icons/Success.svg'
                        : '/assets/icons/info-alert-white.svg'
                }
                width={25}
                height={25}
                alt="alert icon"
            />
            <p className="toast-body__text">{message}</p>
        </div>
    );
}
