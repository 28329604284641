export const API_ROUTES = {
    login: '/login',
    logout: '/logout',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    contact: '/contact-us',
    merchandisesForEnterprise: `/enterprise/my-merchandises`,
    enterpriseOwnData: `/enterprise/get-details`,
    enterpriseUpdate: `/enterprise/update-details`,
    inquiriesForEnterprise: `/enterprise/my-inquiries`,
    inquiriesSearchForEnterprise: `/enterprise/contacts/search`,
    activeGenres: '/active-genres',
    merchandiseByGenre: '/merchandises-by-genres',
    getCarousel: '/carousel',
    createMerchandise: '/enterprise/merchandises',
    merchandiseDetails: (id?: number) => `/merchandise-details/${id}`,
    merchandiseByEnterprise: (id?: number) => `/enterprise/merchandise-details/${id}`,
    updateMerchandise: (id?: number) => `/enterprise/merchandises/${id}`,
    deleteMerchandise: (id?: number) => `/enterprise/merchandises/${id}`,
    addFavorite: '/member/add-to-favourites',
    removeFavorite: '/member/remove-from-favourites',
    viewerOwnData: `/member/get-details`,
    createViewer: '/member/register',
    updateViewer: '/member/update-details',
    viewerFavoriteList: `/member/favourite-merchandises`,
    viewerContactHistories: `/member/contact-history`,
    getRemaingingMerchandises: `/merchandises-by-genre-paginated`,
    getPopularMerchandises: `/popular-merchandises`,
};
