import { useMemo } from 'react';
import { PropsWithChildren } from 'react';
import Link from 'next/link';
import { appRoutes } from '@/utils/routes/routeConfiguration';
import { setInitialLoggedInToken, setInitialLoggedInUser, setLoggedInUserContact } from '@/redux/auth/authSlice';
import { Role, User } from '@/redux/user/slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/rootReducer';
import { useLogoutMutation } from '@/redux/auth/authApi';
import crossIcon from '@/public/assets/icons/Close.svg';
import Image from 'next/image';
import { initialContact } from '@/redux/enterprise/slice';
import { useRouter } from 'next/router';
import './menu.scss';

export interface MenuProps {
    handleClick: () => void;
    isActive: any;
}

const menus = [
    {
        display: 'お問い合わせ一覧',
        scope: [Role.ENTERPRISE, Role.ENTERPRISE_MANAGER],
        route: appRoutes.enterpriseInquiryList,
    },
    {
        display: '商材一覧',
        scope: [Role.ENTERPRISE, Role.ENTERPRISE_MANAGER, Role.ENTERPRISE_SUPERVISOR],
        route: appRoutes.merchandiseList,
    },
    {
        display: '企業情報',
        scope: [Role.ENTERPRISE, Role.ENTERPRISE_MANAGER, Role.ENTERPRISE_SUPERVISOR],
        route: appRoutes.enterpriseInfo,
    },
    {
        display: '会員情報',
        scope: [Role.VIEWING_USER],
        route: appRoutes.viewerMemberInfo,
    },
    {
        display: 'お気に入り',
        scope: [Role.VIEWING_USER],
        route: appRoutes.memberFavorites,
    },
    {
        display: 'お問い合わせ履歴',
        scope: [Role.VIEWING_USER],
        route: appRoutes.memberInquiryList,
    },
];

function Menu({ children, handleClick, isActive }: PropsWithChildren<MenuProps>) {
    const dispatch = useDispatch();
    const router = useRouter();
    const [logout] = useLogoutMutation();
    const loggedInUser = useSelector<RootState, User>((state) => state.auth.loggedInUser);

    const userMenus = useMemo(() => {
        const userRoles = new Set(loggedInUser.roles?.map((role) => role.title));
        return menus.filter((menu) => {
            const itemRoles = new Set(menu.scope.map((role) => role));
            return Array.from(userRoles).some((role) => itemRoles.has(role));
        });
    }, [loggedInUser.roles]);

    async function handleLogout() {
        logout()
            .unwrap()
            .then(async () => {
                await router.replace(appRoutes.home);
                dispatch(setInitialLoggedInUser());
                dispatch(setInitialLoggedInToken());
                dispatch(setLoggedInUserContact(initialContact()));
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <div className="menu-container">
            <div
                className={`menu-container__icon ${isActive ? 'menu-container__icon--active' : null}`}
                onClick={handleClick}
            >
                {children}
            </div>
            {isActive && (
                <div
                    className={`menu-container__text ${
                        isActive ? 'menu-container--active' : 'menu-container--inactive'
                    }`}
                >
                    <Image
                        className="menu-container__cross-img"
                        src={crossIcon}
                        alt="cross-icon"
                        onClick={handleClick}
                        loading="eager"
                    />
                    <ul className="menu-container__list">
                        {userMenus.map((menu, i) => (
                            <Link href={menu.route} key={i} className="menu-container__item">
                                {menu.display}
                            </Link>
                        ))}
                        <li className="menu-container__item" onClick={handleLogout}>
                            ログアウト
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default Menu;
