import Image from 'next/image';
import { toast } from 'react-toastify';
import ToastBody, { ToastBodyProps } from './body';

export function ErrorToastContainer(props: ToastBodyProps) {
    return toast(<ToastBody message={props.message} type="danger" />, {
        position: 'top-center',
        hideProgressBar: true,
        className: 'custom_toast custom_toast--error',
        bodyClassName: 'custom_toast__body',
        closeButton: <Image src="/assets/icons/CloseToaster.svg" width={20} height={20} alt="notification close" />,
    });
}

export function SuccessToastContainer(props: ToastBodyProps) {
    return toast(<ToastBody message={props.message} type="success" />, {
        position: 'top-center',
        hideProgressBar: true,
        className: 'custom_toast custom_toast--success',
        bodyClassName: 'custom_toast__body',
        closeButton: <Image src="/assets/icons/CloseToaster.svg" width={20} height={20} alt="notification close" />,
    });
}

export function InfoToastContainer(props: ToastBodyProps) {
    return toast(<ToastBody message={props.message} type="info" />, {
        position: 'top-center',
        hideProgressBar: true,
        className: 'custom_toast custom_toast--info',
        bodyClassName: 'custom_toast__body',
        closeButton: <Image src="/assets/icons/CloseToaster.svg" width={20} height={20} alt="notification close" />,
    });
}
